const teamData = [
    {
        id: 1,
        name: "Талант Арыстанов",
        responsibility: "Организатор"
    },
    {
        id: 2,
        name: "Артур Кутлугильдин",
        responsibility: "Организатор"
    },
    {
        id: 3,
        name: "Бектур",
        responsibility: "Координатор"
    },
    {
        id: 4,
        name: "Эльдана",
        responsibility: "Координатор"
    },
    {
        id: 5,
        name: "Саидислом",
        responsibility: "Sumo"
    },
    {
        id: 6,
        name: "Магомед",
        responsibility: "Sumo"
    },
    {
        id: 7,
        name: "Абай",
        responsibility: "Line follower"
    },
    {
        id: 8,
        name: "Нурсезим",
        responsibility: "Line follower"
    },
    {
        id: 9,
        name: "Баястан",
        responsibility: "Кегель ринг"
    },
    {
        id: 10,
        name: "Азалия",
        responsibility: "Кок Бору"
    },
    {
        id: 11,
        name: "Алтынай",
        responsibility: "Дроны"
    },
    {
        id: 12,
        name: "Айбийке",
        responsibility: "Дроны"
    },
    {
        id: 13,
        name: "Гульзада",
        responsibility: "Дроны"
    },
    {
        id: 14,
        name: "Эрлан",
        responsibility: "Миникубок Голдберга"
    },
    {
        id: 15,
        name: "Шахназа",
        responsibility: "Гонка"
    },
    {
        id: 16,
        name: "Бермет",
        responsibility: "Гонка"
    },
    {
        id: 17,
        name: "Байсал",
        responsibility: "Mailo"
    },
    {
        id: 18,
        name: "Азис",
        responsibility: "Mailo"
    },
    {
        id: 19,
        name: "Санжар",
        responsibility: "Миникубок Голдберга"
    },
    {
        id: 20,
        name: "Нурсултан",
        responsibility: "Вездеход"
    },
    {
        id: 21,
        name: "Тынай",
        responsibility: "Перетягивание каната"
    },
    {
        id: 22,
        name: "Санжар М",
        responsibility: "Перетягивание каната"
    },
    {
        id: 23,
        name: "Азирет",
        responsibility: "Карантинная зона"
    },
    {
        id: 24,
        name: "Эмир",
        responsibility: "Разработчик"
    },
]
export default teamData;